@import "common";
@import "interface";
@import "icons";
@import "code";
@import "form";
@import "dashboard";
@import "chat";

// fonts
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100..700&family=JetBrains+Mono:wght@100..700');

*,
*::before,
*::after {
  box-sizing: border-box;
}


// init
html, body, #root {
  margin:0;
  padding:0;
  background: $main-background;
  color: $main-font;
  min-height: 100vh;
}


@keyframes embolden {
  0% {
    font-variation-settings: 'wght' 100;
    color:$main-font;
  }
  60% {
    font-variation-settings: 'wght' 700;
    color:lighten($main-font, 50%);
  }

  100% {
    font-variation-settings: 'wght' 100;
    color:$main-font;
  }
}

@keyframes ping {
  0% {
    background-color: #773;
  }
  100% {
    background-color: inherit;
  }
}


.flash {
  // font-size:120%;
  animation: embolden 2000ms infinite forwards;
}

.renderFlash {
  animation: ping 500ms forwards;
}


