@import "common";

.chat-container {
  background:darken($main-background, 50%);
  display:flex;
  flex-direction: column-reverse;
  overflow: auto;
  max-height:300px;
  min-height:200px;
  .chat-message {
    background: darken($main-background, 10%);
    padding:0;
    margin:1px;
    display:flex;
    font-size:80%;
    .chat-message-sender {
      background: darken($main-background, 15%);
      font-family:$title-font-family;
      font-variation-settings: 'wght' 700;
      border-right:1px solid $dark-highlight;
      text-align:right;
      width:120px;
      max-width:120px;
      height:100%;
      vertical-align: top;
      padding:.25em 1em .25em .25em;
      &.author { color: #dddd95}
      &.owner { color: $main-highlight}
      overflow:hidden;
    }
    .chat-message-content {
      height:100%;
      vertical-align: top;
      padding:.25em .25em .25em 1em;
      &.ghost { color:darken($main-font,40%); }
    }
  }
}
.chat-entry {
  background:darken($main-background, 50%);
  padding:0;
  display:flex;
  input {
    width:100%;flex-basis:100%;border:0;height:100%;padding:.5em 1em;border-radius:0;background:darken($main-background, 15%);color:$main-font;outline:none;
    &::placeholder { color:darken($main-font,25%); opacity:1; }
    line-height:1.5em;
    margin:1px;

  }
  button {
    border:0;
    padding:.5em 1em;
    border-radius:0;
    box-sizing: border-box;height:100%;
    line-height:1.2em;
    margin:1px;
  }
}
