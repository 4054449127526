#divcont { background:#676778;margin:0;padding:0;box-sizing:border-box;font-family:trebuchet ms,arial,helvetica,sans-serif;position:relative;height:100%;width:100%;min-width:800px;min-height:800px; }
#divcont h1 { color:black;text-align:center;width:100%;text-shadow:0 1px 7px rgba(255,127,127,.7);font-size:300%; }
#divcont p { color:black;text-align:center;width:80%;margin:auto;font-size:125%; }

#divide {
    height:50%;top:50%;width:100%;position:absolute; margin:0;padding:0;
    text-align:center;
    color:white;
    background:black;
    z-index:8;
}
#divide-inner {
    position:absolute;overflow:hidden;top:0;left:50%;transform:translateX(-50%) translateY(-50%) rotate(0deg);width:300px;height:300px;border-radius:300px;
    transition:transform .5s linear 0s,box-shadow .5s linear 0s,opacity .1s linear 1.01s;
    background:#676778;
    box-shadow:0 1px 1px -2px black;
    opacity:0;
}
#divide-inner:before {
    content:'';position:absolute;z-index:8;top:50%;left:0;right:0;bottom:0;background:black;
}
#divide:hover #divide-inner {
    transform:translateX(-50%) translateY(-50%) rotate(90deg);
    transition:transform 1s linear 1s,box-shadow 1s linear 1s,opacity .1s linear 0s;
    box-shadow:0 0 15px 4px rgba(255,127,127,.5);
    opacity:1;
}
#divide-inner:after {
    content:'';
    background-image:radial-gradient(120px at 90px 100px, rgba(255,232,232,.25) 0%, rgba(255,255,255,0) 100%);
    z-index:20;
    width:100%;height:100%;
    position:absolute;
    top:0;left:0;
    transform:rotate(0deg);
    transition:transform .5s linear 0s,opacity 1s ease-out 0s;
    opacity:0;
}
#divide:hover #divide-inner:after {
    transform:rotate(-90deg);
    transition:transform 1s linear 1s,opacity 2s ease-in 0s;
    opacity:1;
}


#body-w,#body-b {
    position:absolute;
    left:50%;
    top:50%;
    height:100px;
    width:200px;
    border-radius:200px 200px 0 0;
    transform-origin:50% 100%;
    transform:translateX(-50%) translateY(-100%) rotate(-180deg);
    box-shadow:none;
}
#body-w {
    transition:transform .5s linear .5s, z-index 0s linear .5s,box-shadow .5s linear 0s;
    background:black;
    z-index:6;
}
#body-b {
    transition:transform 1s linear 0s, z-index 0s linear .49s,box-shadow .5s linear 0s;
    background:#676778;
    z-index:3;
}
#divide:hover #body-w  {
    transform:translateX(-50%) translateY(-100%) rotate(0deg);
    transition:transform .5s linear 0s, z-index 0s linear .5s,box-shadow .5s linear 1s;
    box-shadow:0 -1px 7px 1px rgba(255,127,127,.5);
    z-index:10;
}
#divide:hover #body-b  {
    transition:transform 1s linear 0s, z-index 0s linear .5s,box-shadow .5s linear 1s;
    transform:translateX(-50%) translateY(-100%) rotate(180deg);
    box-shadow:0 -1px 7px 1px rgba(255,127,127,.5);
    z-index:9;
}


#head-w,#head-b {
    position:absolute;
    left:50%;
    top:50%;
    height:100px;
    width:100px;
    border-radius:100px;
    transform-origin:50% 100%;
    transform:translateX(-50%) translateY(-100%) rotate(-180deg);
}
#head-w {
    transition:transform .5s linear .5s, z-index 0s linear .5s;
    background:black;
    z-index:5;
}
#head-b {
    transition:transform 1s linear 0s, z-index 0s linear .35s;
    background:#676778;
    z-index:4;
}
#divide:hover #head-w  {
    transform:translateX(-50%) translateY(-100%) rotate(90deg);
    transition:transform .5s linear 0s, z-index 0s linear .5s;
    z-index:12;
}
#divide:hover #head-b {
    transition:transform 1s linear 0s, z-index 0s linear .85s;
    transform:translateX(-50%) translateY(-100%) rotate(270deg);
    z-index:11;
}


#head-w:after,#head-b:after {
    content:'';
    position:absolute;
    left:50%;
    top:0;
    height:20px;
    width:20px;
    border-radius:20px;
    transform:translateX(-50%) translateY(40px);
    transition:opacity .5s linear 0s;
}
#head-b:after {	background:black; opacity:0; }
#head-w:after { background:#676778; opacity:0; }
#divide:hover #head-b:after { opacity:1; transition:opacity .5s linear 1s; }
#divide:hover #head-w:after { opacity:1; transition:opacity .5s linear 1s; }
